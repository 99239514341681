@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
body{
  background: white;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}
#menuTarget{
  display: flex;
  flex-direction: row;
  .menuTarget{
    height: 29px;
    margin-right: 40px;
    cursor: pointer;
  }

  .active{
    border-bottom: 4px solid #FF7373;
  }
}

.header{
  font-size: 20px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  margin-bottom: 24px;
  padding: 10px 32px 0px 32px;
  .name{
    font-weight: bold;
    margin: 0 30px 0 6px;
  }
  a.logo{
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: inherit;
  }
  .aboutLink{
    justify-self: end;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    a{
      text-decoration: none;
      color: inherit;
    }
  }

}

.content{
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 0 32px;

  #menu{
    .menu{
      width: 271px;
      font-size: 20px;
      cursor: pointer;
      background: #FFFFFF;
      /* Accent */
      padding: 12px 15px;
      margin-left: -15px;
      line-height: 23px;
    }

    .active{
      border: 2px solid #FF7373;
      box-sizing: border-box;
      box-shadow: inset 0px 4px 4px rgba(163, 0, 0, 0.25);
      border-radius: 4px;
    }

  }
  .wrap{
    display: flex;
    flex-direction: column;
    h1{
      font-size: 40px;
      line-height: 47px;
      font-weight: bold;
      margin: 0 0 10px 20px;
    }
    #root{

    }
    .notes{
      font-size: 20px;
      line-height: 120%;
      h2{
        margin: 0 0 0.5em 0;
      }

    }
  }

}

.minimap{
  &.hidden{
    display: none;
  }
}

.mainChart{
  .boxes{
    cursor: pointer;
  }
  text.label{
    font-size: 12px;
    pointer-events: none;
    text-anchor:start;
  }
  text.value{
    font-size: 16px;
    font-weight: bold;
    pointer-events: none;
    text-anchor:start;

  }

}
.footer{
  padding: 24px 32px;
  font-size: 20px;
  a{
    border-bottom: 1px solid black;
  }
}

a{
  text-decoration: none;
  color: inherit;
}
